#input-form {
    margin-left: 11vh;
    margin-right: 15vh;
}

#project-details {
    width: 100vh;
} 

@media (max-width:700px) {
    #input-form {
        margin-left: 4vh;
    }
    #project-details {
        width: 100%;
    } 
}