#left-column {
    margin-top: 3vh;
    padding-left: 0%;
}
#right-column {
    margin-top: 3vh;
}
#footer-specs {
    /* background-color: #1c3448; */
    background-color: black;
    width: 100%;
    margin-top: 3%;
}
#footer-text {
    color: white;
    font-family: 'Roboto', sans-serif;
    text-align: center
}
#contact-text{
    font-family: 'Roboto', sans-serif;
}
#map {
    height: 30vh;
    width: 60vh;
    margin-left: 18%;
    margin-top: 5%;
}
#lower-footer {
    height:5%;
    font-family: 'Roboto', sans-serif;
}
#footer-img {
     height: 50%;
     width: 50%;
     /* text-align: center; */
     margin-right: 4%;

}

@media (max-width:700px) {
    #footer {
        display: flex;
        flex-wrap: wrap;
    }
    div {
        flex-basis: 100%;
    }
    #map {
        height: 30vh;
        width: 104%;
        margin-left: 0%;
        margin-top: 5%; 
        z-index: 1;
    }
    #left-column {
        margin-top: 5vh;
    }
    #right-column {
        margin-top: 8vh;
        margin-bottom: 5vh;
    }
    #footer-img {
        height: 170%;
        width: 170%;       
        display: block;
        margin-left: -37%;
        margin-right: 0%;
    }
}