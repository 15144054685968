#excavations {
    background-image: url('../../photos/Excavator\ Mountain.jpg');
    height: 75vh;
    background-position: center;
    background-size: cover;
}
#excavations-banner {
    padding-bottom: 5vh;
    margin-top: 5vh;
    font-size: 30px;
}
#excavations-text-content {
    margin-left: 10vh;
    margin-right: 18vh;
    margin-bottom: 10vh;
    font-family: 'Roboto', sans-serif;
}

@media (max-width:700px) {
    #excavations {
        height: 60vh;
    }

    #excavations-text-content {
        margin-left: 10vh;
        margin-right: 10vh;
    }
}