#about {
    margin-bottom: 15vh;
    margin-left: 5vh;
}
#grid {
    margin-top: 5vh;
    margin-bottom: -5vh;
}
#about-banner {
    margin-top: 6vh;
    margin-bottom: 2vh
}

#about-photo {
    height: 55vh;
    width: 570;
    background-position: center;
    background-size: cover;
    margin-left: 8vh;
    margin-top: 9vh;
    margin-bottom: 10vh;

}

#about-text-content {
    padding-left: 15vh;
    height: 80vh;
    padding-bottom: 30vh;
}
#about-background {
    margin-left: 15vh;
    margin-right: 15vh;
    margin-top: 15vh;   
}


@media (max-width: 700px) {
    #about {
        margin: 0;
    }
    #about-photo {
        margin: 0;
        height: 100%;
        width: 100%;
    }
    #about-banner-text {
        padding-bottom: 2vh;
        margin-top: 5vh;
        font-size: 30px;
    }
    #about-text-content {
        margin-left: -3vh;
        margin-right: 10vh;
        margin-bottom: 75%;
        text-align: center;
    }

    #about-background {
        margin-left: 8vh;
        margin-right: 10vh;
        padding-bottom: 10vh;
    }
}